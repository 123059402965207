import { Button } from "@mui/material";

export const ExportButton = ({ items, applicationName }) => {
  const downloadJSON = () => {
    let exportData = {};
    items.forEach((item) => {
      exportData[item.key] = item.value;
    });

    const dateString = new Date()
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("");

    const jsonData = new Blob([JSON.stringify(exportData, null, 4)], {
      type: "application/json",
    });
    const jsonURL = URL.createObjectURL(jsonData);
    const link = document.createElement("a");
    link.href = jsonURL;
    link.download = `${applicationName}.en.${dateString}.json`;
    link.click();
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      className="button languageDisplay_saveBtns"
      onClick={downloadJSON}
    >
      Download JSON
    </Button>
  );
};

export const ImportButton = ({ items, languageCode, uploadEl, ULTI, ULTIEl }) => {
  const uploadJSON = () => {
    console.log();
    if (uploadEl.files[0] === undefined) {
      alert("Please choose a file");
      return;
    }
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      (event) => {
        try {
          const res = JSON.parse(event.target.result);
          const uploadArr = [];
          const missedKeys = [];

          for (const [key, value] of Object.entries(res)) {
            let matchingItem = items.find((item) => item.key === key);
            if (matchingItem === undefined) {
              missedKeys.push(key);
            } else {
              uploadArr.push({
                id: null,
                value: value,
                last_updated: null,
                language_item: matchingItem.id,
                language_code: languageCode,
              });
            }
          }

          uploadArr.forEach(i => {
            console.log(i.language_item)
            let matchIndex = ULTI.findIndex(item => item.language_item === i.language_item);
            if (matchIndex === -1) {
              ULTI.push(i)
            } else {
              ULTI[matchIndex].value = i.value
            }
          })

          ULTIEl.forceUpdate();

          if (missedKeys.length > 0) {
            alert(`Missed keys:\n${missedKeys.join("\n")}`)
          }
        } catch (e) {
          // TODO: HANDLE PARSE FAIL
          console.warn(e)
          alert("Failed to parse JSON!");
          return;
        }
      },
      false
    );
    reader.readAsText(uploadEl.files[0]);
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      className="button languageDisplay_saveBtns"
      onClick={uploadJSON}
    >
      Apply JSON
    </Button>
  );
};
