import React from "react";
import {Link} from "react-router-dom";
import Header from "../header/header";
import {authenticationService} from "../scripts/authentication.service";
import {URL} from "../scripts/constants";
import {cf} from "../scripts/custom-fetch";
import {v4 as UUID} from "uuid";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Pagination,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    TextField,
    InputAdornment,
    Select,
    Button,
    Tooltip,
    Checkbox
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import DeleteIcon from "@mui/icons-material/Delete";
// import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import {withSnackbar} from "notistack";
import Content from "../api/content";
import {searchService} from "../scripts/search.service";

class Personas extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.searchContent = this.searchContent.bind(this);

        this.state = {
            error: null,
            isLoaded: false,
            open: false,
            openView: false,
            openReelView: false,
            openVideoView: false,
            openDelete: false,
            restore: false,
            deleteID: 0,
            linkID: "",
            currentPage: searchService.currentSearchValue.page || 1,
            itemsPerPage: 10,
            currentSearch: searchService.currentSearchValue.search || "",
            currentType: searchService.currentSearchValue.type || [],
            deletedItems: [],
            newPersona: false,
            newPersonaUUID: "",
            persona: {
                name: "",
                title: "",
                type: "",
                pic: "",
            },
            items: [],
            currentUser: authenticationService.currentUserValue,
            searchPersonas: [],
            selectedRows: []
        };
    }

    callPersona() {
        const options = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
        };
        cf.get(URL + "/db/getPersonas", options).then(
            (result) => {
                if (this.state.copyID) {
                    let copy = result.find((obj) => obj.id === this.state.copyID);
                    window.open(window.location.href + "/edit/" + copy.uuid);
                }
                this.setState(
                    {
                        items: result,
                        isLoaded: true,
                        searchPersonas: result
                    },
                    () => {
                        this.searchContent();
                    }
                );
            },
            (error) => {
                this.setState({
                    error,
                    isLoaded: true,
                });
            }
        );
    }

    close(e) {
        e.preventDefault();
        this.setState({
            open: false,
        });
    }

    open() {
        this.setState({
            open: true,
        });
    }

    openRestore = () => {
        Content.getDeletedItems(10).then((result) => {
            if (result) {
                this.setState({
                    deletedItems: result,
                    restore: true,
                });
            }
        });
    };

    closeRestore = () => {
        this.setState({
            restore: false,
        });
    };

    openDelete = (item) => {
        this.setState({
            openDelete: true,
            deleteID: item,
        });
    };

    closeDelete = () => {
        this.setState({
            openDelete: false,
        });
    };

    openView(item) {
        this.setState({
            openView: true,
            linkID: item,
        });
    }

    closeView(e) {
        e.preventDefault();
        this.setState({
            openView: false,
        });
    }

    openReelView(item) {
        this.setState({
            openReelView: true,
            linkID: item,
        });
    }

    closeReelView(e) {
        e.preventDefault();
        this.setState({
            openReelView: false,
        });
    }

    openVideoView(item) {
        this.setState({
            openVideoView: true,
            linkID: item,
        });
    }

    closeVideoView(e) {
        e.preventDefault();
        this.setState({
            openVideoView: false,
        });
    }

    setPage(event, value) {
        let data = searchService.currentSearchValue;
        data.page = value;
        searchService.setSearch(data);
        this.setState({
            currentPage: value,
        });
    }

    handleChange(event) {
        let temp = this.state.persona;
        temp[event.target.name] = event.target.value;
        this.setState({persona: temp});
    }

    newPersona() {
        this.props.enqueueSnackbar("Adding new persona", {variant: "info"});
        let uuid = UUID();
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
            body: JSON.stringify({
                name: this.state.persona.name,
                title: this.state.persona.title,
                type: this.state.persona.type,
                pic: this.state.persona.pic,
                uuid: uuid,
            }),
        };

        cf.get(`${process.env.REACT_APP_API_POST}/db/newPersona`, options).then(
            (result) => {
                console.log(result);
                this.props.enqueueSnackbar("Added new persona", {variant: "success"});
                this.setState(
                    {
                        persona: {
                            name: "",
                            title: "",
                            type: "",
                            pic: "",
                        },
                        newPersona: true,
                        newPersonaUUID: uuid,
                    },
                    () => {
                        this.callPersona();
                    }
                );
            },
            (error) => {
                this.props.enqueueSnackbar("Something went wrong", {
                    variant: "error",
                });
                console.log(error);
            }
        );
    }

    updateSearchText = (event) => {
        let data = searchService.currentSearchValue;
        data.search = event.target.value;
        searchService.setSearch(data);
        this.setState(
            {
                currentSearch: event.target.value,
            },
            () => {
                this.searchContent();
            }
        );
    };

    updateSearchType = (event) => {
        let data = searchService.currentSearchValue;
        data.type = event.target.value;
        searchService.setSearch(data);
        this.setState(
            {
                currentType: event.target.value,
            },
            () => {
                this.searchContent();
            }
        );
    };

    getFilteredsearchPersonas(items, currentSearch) {
        if (currentSearch.length === 0) {
            return items;
        }

        if (isNaN(currentSearch)) {
            // Text search - filter on title
            return items.filter((obj) => {
                return obj.title
                    .toUpperCase()
                    .includes(currentSearch.toUpperCase());
            });
        } else {
            // Number search - filter on ID
            return items.filter((obj) => {
                return String(obj.id).indexOf(currentSearch) > -1;
            });
        }
    }

    searchContent() {
        let items = this.state.items;

        items = this.getFilteredsearchPersonas(items, this.state.currentSearch);

        let internal = false;
        if (this.state.currentType.length > 0) {
            let currentType = this.state.currentType;
            currentType.forEach((type) => {
                if (type === 1) {
                    items = items.filter((persona) => {
                        return persona.master_persona === 1;
                    });
                }
                if (type === 2) {
                    internal = true;
                }
                if (type === 3) {
                    items = items.filter((persona) => {
                        return persona.type === 1;
                    });
                }
                if (type === 4) {
                    items = items.filter((persona) => {
                        return persona.type === 2;
                    });
                }
                if (type === 6) {
                    items = items.filter((persona) => {
                        return persona.type === 4;
                    });
                }
                if (type === 7) {
                    items = items.filter((persona) => {
                        return persona.type === 5;
                    });
                }
                if (type === 8) {
                    items = items.filter((persona) => {
                        return persona.type === 6;
                    });
                }
                if (type === 9) {
                    items = items.filter((persona) => {
                        return persona.type === 7;
                    });
                }
            });
        }
        if (!internal) {
            items = items.filter((persona) => {
                return persona.internally_managed === 1;
            });
        }

        this.setState({searchPersonas: items});
    }

    copy(id, type) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
            body: JSON.stringify({
                id: id,
                type: type,
            }),
        };

        cf.get(`${process.env.REACT_APP_API_POST}/db/copyPersona`, options).then(
            (result) => {
                console.log(result);
                this.setState({copyID: result.insertId});
                this.callPersona();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    delete = () => {
        let state;
        let msg1;
        let msg2;
        if (this.state.restore) {
            msg1 = "Restoring";
            msg2 = "Restored";
            state = 1;
        } else {
            msg1 = "Deleting";
            msg2 = "Deleted";
            state = 3;
        }
        this.props.enqueueSnackbar(msg1, {variant: "info"});
        this.setState({
            openDelete: false,
        });
        Content.disable(10, state, this.state.deleteID).then((result) => {
            this.callPersona();
            this.setState({
                restore: false,
            });
            console.log(result);
            this.props.enqueueSnackbar(msg2, {variant: "success"});
        });
    };

    componentDidMount() {
        this.callPersona();
    }

    handleSelectRow = (id) => {
        const {selectedRows} = this.state;
        const updatedSelectedRows = selectedRows.includes(id) ? selectedRows.filter(rowId => rowId !== id) : [...selectedRows, id];

        this.setState({
            selectedRows: updatedSelectedRows,
            selectedAll: updatedSelectedRows.length === this.state.searchPersonas.length,
        });
    }

    handleSelectAll = (event) => {
        const {searchPersonas} = this.state;
        if (event.target.checked) {
            const allRowsIds = searchPersonas.map(item => item.id);
            this.setState({
                selectedRows: allRowsIds,
                selectAll: true
            });
        } else {
            this.setState({
                selectedRows: [],
                selectAll: false
            });
        }
    }

    deleteSelectedRows = () => {
        const {selectedRows, searchPersonas} = this.state;
        const state = this.state.restore ? 1 : 3;
        this.props.enqueueSnackbar("Deleting selected personas", {variant: "info"});

        Promise.all(
            selectedRows.map(id => Content.disable(11, state, id))
        ).then(() => {

            const updatedPersonas = searchPersonas.filter(persona => !selectedRows.includes(persona.id));

            this.setState({
                searchPersonas: updatedPersonas,
                selectedRows: [],
                selectAll: false
            });
            this.props.enqueueSnackbar("Selected personas deleted", {variant: "success"});
        });
    };

    render() {
        const {
            searchPersonas,
            error,
            isLoaded,
            open,
            openView,
            openReelView,
            openVideoView,
            linkID,
            currentType,
            persona,
            currentPage,
            currentSearch,
            itemsPerPage,
            openDelete,
            restore,
            deletedItems,
            currentUser,
            newPersona,
            newPersonaUUID,
        } = this.state;
        const indexOfLastPost = currentPage * itemsPerPage;
        const indexOfFirstPost = indexOfLastPost - itemsPerPage;
        const perms = currentUser.perms;
        let currentItems;
        if (restore) {
            currentItems = deletedItems.slice(indexOfFirstPost, indexOfLastPost);
        } else {
            currentItems = searchPersonas.slice(indexOfFirstPost, indexOfLastPost);
        }
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width: "50%"}}>
                        <LinearProgress/>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Header/>

                    <div style={{left: "37rem", position: "relative", width: "250px", top: "-2.5rem"}}>
                        <div className="links" style={{justifyContent: "space-between", display: "flex"}}>
                            {restore ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.closeRestore()}
                                    className="spacer"
                                >
                                    Normal
                                </Button>
                            ) : (
                                <Button
                                    disabled={!perms.p3}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.openRestore()}
                                    className="spacer"
                                >
                                    Restore
                                </Button>
                            )}
                            <Button
                                disabled={!perms.p1}
                                variant="contained"
                                color="primary"
                                onClick={() => this.open()}
                                className="spacer"
                            >
                                New
                            </Button>
                            <Link to="/">
                                <Button variant="contained" color="primary">
                                    Back
                                </Button>
                            </Link>
                        </div>
                    </div>

                    <div
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            marginLeft: "50px",
                            padding: "15px",
                            backgroundColor: "rgba(0, 0, 0, 0.65)",
                            display: "flex",
                            borderRadius: "0 0 5px",
                        }}
                    >
                        <TextField
                            label="Search"
                            variant="outlined"
                            focused
                            value={currentSearch}
                            className="foreInputWhite"
                            style={{marginRight: "10px", width: "250px", color: "white"}}
                            onChange={(e) => this.updateSearchText(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary"/>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <FormControl
                            style={{width: "100%"}}
                            variant="outlined"
                            color="primary"
                            focused
                        >
                            <InputLabel>Filter</InputLabel>
                            <Select
                                value={currentType}
                                onChange={this.updateSearchType}
                                multiple
                                className="foreInputWhite"
                                label="Type"
                            >
                                <MenuItem value={1}>Master</MenuItem>
                                <MenuItem value={2}>System Generated</MenuItem>
                                <MenuItem value={3}>Default</MenuItem>
                                <MenuItem value={4}>Reel</MenuItem>
                                <MenuItem value={6}>Gmail</MenuItem>
                                <MenuItem value={7}>Music</MenuItem>
                                <MenuItem value={8}>Article</MenuItem>
                                <MenuItem value={9}>Video</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div style={{marginTop: "5px", paddingTop: "0"}} className="social">
                        <div className="box">
                            <div className="items" style={{width: "90%", margin: "0 auto"}}>
                                <TableContainer component={Paper} style={{maxHeight: "77vh", top: "0rem"}}
                                                className='profiles-container'>
                                    <Table>
                                        <TableHead className='table_head' style={{height: "50px"}}>
                                            <TableRow>
                                                <TableCell className="table_cell top_row" style={{width: "16%"}}>
                                                    <div style={{display: "flex"}}>
                                                        <div className="table_cell top_row"
                                                             style={{display: "flex", flexDirection: "column"}}>
                                                            <span style={{fontWeight: "600"}}>Select All</span>


                                                            <Checkbox className="table_cell top_row"
                                                                      checked={this.state.selectAll}
                                                                      onChange={this.handleSelectAll}></Checkbox>
                                                        </div>
                                                        {this.state.selectedRows.length > 0 && (
                                                            <Button variant="contained" color="secondary" size="small"
                                                                    onClick={this.deleteSelectedRows} style={{
                                                                padding: "0.25rem",
                                                                width: "58%",
                                                                height: "90%",
                                                                top: "2.5rem"
                                                            }}>DELETE SELECTED</Button>
                                                        )}
                                                    </div>

                                                </TableCell>
                                                <TableCell className="table_cell top_row"
                                                           style={{fontWeight: "600", width: "10%"}}>ID</TableCell>
                                                <TableCell className="table_cell top_row"
                                                           style={{fontWeight: "600", width: "30%"}}>Title</TableCell>
                                                <TableCell className="table_cell top_row"
                                                           style={{width: "20%", fontWeight: "600"}}>Name</TableCell>
                                                <TableCell className="table_cell top_row"
                                                           style={{fontWeight: "600", width: "10%"}}>Type</TableCell>
                                                <TableCell className="table_cell top_row" style={{
                                                    width: "30%",
                                                    fontWeight: "600",
                                                    paddingRight: "2rem"
                                                }}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {currentItems.map((item, index) => (
                                                <TableRow key={item.id}
                                                          className={index % 2 === 0 ? 'dark_row' : 'light_row'}>
                                                    <TableCell style={{padding: "0.25rem 2.25rem"}}>
                                                        <Checkbox className="table_cell"
                                                                  checked={this.state.selectedRows.includes(item.id)}
                                                                  onChange={() => this.handleSelectRow(item.id)}></Checkbox>
                                                    </TableCell>
                                                    <TableCell className="table_cell"
                                                               style={{padding: "0.25rem 1rem 0.25rem 0.5rem"}}>{item.id}</TableCell>
                                                    <TableCell className="table_cell"
                                                               style={{padding: "0.25rem 1rem 0.25rem 1rem"}}>
                                                        <Tooltip
                                                            title={
                                                                <div style={{fontSize: "16px"}}>
                                                                    {item.title}
                                                                </div>
                                                            }
                                                            aria-label="add"
                                                        >
                                                            <div
                                                                style={{
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                            >
                                                                {item.title}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell className="table_cell"
                                                               style={{padding: "0.25rem 1rem 0.25rem 0.5rem"}}>
                                                        <Tooltip
                                                            title={
                                                                <div style={{fontSize: "16px"}}>
                                                                    {item.name}
                                                                </div>
                                                            }
                                                            aria-label="add"
                                                        >
                                                            <div
                                                                style={{
                                                                    fontSize: "16px",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                            >
                                                                {item.name}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell className="table_cell">
                                                        {item.type === 1 && "Default"}
                                                        {item.type === 2 && "Reel"}
                                                        {item.type === 4 && "Gmail"}
                                                        {item.type === 5 && "Music"}
                                                        {item.type === 6 && "Article"}
                                                        {item.type === 7 && "Video"}
                                                    </TableCell>

                                                    <TableCell style={{padding: "0.25rem 1rem 0.25rem 1.5rem"}}>
                                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                                            <div style={{
                                                                width: "175px",
                                                                justifyContent: "space-between",
                                                                display: "flex"
                                                            }}>
                                                                <Link
                                                                    to={
                                                                        !perms.p2
                                                                            ? `/personas`
                                                                            : `/personas/edit/${item.uuid}`
                                                                    }
                                                                >
                                                                    <Button style={{height: "31px"}}
                                                                            disabled={!perms.p2}
                                                                            size="small"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </Link>
                                                                {item.type === 1 ? (
                                                                    <Button
                                                                        onClick={() => this.openView(item.uuid)}
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                    >
                                                                        View
                                                                    </Button>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {item.type === 2 ? (
                                                                    <Button
                                                                        onClick={() => this.openReelView(item.uuid)}
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                    >
                                                                        View
                                                                    </Button>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {item.type === 4 ? (
                                                                    <Link
                                                                        to={`/gmail/view/${item.uuid}`}
                                                                        target="_blank"
                                                                    >
                                                                        <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                        >
                                                                            View
                                                                        </Button>
                                                                    </Link>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {item.type === 5 ? (
                                                                    <Link
                                                                        to={`/music/view/${item.uuid}`}
                                                                        target="_blank"
                                                                    >
                                                                        <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                        >
                                                                            View
                                                                        </Button>
                                                                    </Link>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {item.type === 6 ? (
                                                                    <Link
                                                                        to={`/article/view/${item.uuid}`}
                                                                        target="_blank"
                                                                    >
                                                                        <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                        >
                                                                            View
                                                                        </Button>
                                                                    </Link>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {item.type === 7 ? (
                                                                    <Button
                                                                        onClick={() => this.openVideoView(item.uuid)}
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                    >
                                                                        View
                                                                    </Button>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <Button
                                                                    disabled={!perms.p1}
                                                                    onClick={() => this.copy(item.id, item.type)}
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                >
                                                                    Copy
                                                                </Button>
                                                            </div>
                                                            {/* <div>
                            {perms.p3 && (
                              <div onClick={() => this.openDelete(item.id)}>
                                 <DeleteIcon fontSize="large" /> :{" "} }
                                <DeleteIcon color="primary" fontSize="large" />
                              </div>
                            )}
                          </div>   */}
                                                        </div>


                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                    <div className="pagination-container">
                        <Pagination color="secondary" count={Math.ceil(searchPersonas.length / itemsPerPage)}
                                    page={currentPage} onChange={(event, page) => this.setPage(event, page)}/>
                    </div>
                    <div className={openDelete ? "user-box" : "user-box hide"}>
                        <div className="user-container">
                            <p>Are you sure ?</p>
                            <div style={{display: "flex", marginTop: "10px"}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{flex: 1, marginRight: "10px"}}
                                    onClick={this.delete}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{flex: 1}}
                                    onClick={this.closeDelete}
                                >
                                    Nope
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={open ? "user-box" : "user-box hide"}>
                        <div className="user-container">
                            <TextField
                                className="spacer"
                                variant="outlined"
                                label="Title"
                                value={persona.title}
                                name="title"
                                onChange={this.handleChange}
                            />
                            <TextField
                                style={{paddingTop: "10px"}}
                                variant="outlined"
                                label="Name"
                                value={persona.name}
                                name="name"
                                onChange={this.handleChange}
                            />
                            <TextField
                                style={{paddingTop: "10px"}}
                                variant="outlined"
                                label="Picture"
                                value={persona.pic}
                                name="pic"
                                onChange={this.handleChange}
                            />
                            <FormControl style={{paddingTop: "10px"}} variant="outlined">
                                <InputLabel>Type</InputLabel>
                                <Select
                                    onChange={this.handleChange}
                                    label="Type"
                                    value={persona.type}
                                    name="type"
                                >
                                    <MenuItem value={1}>Default</MenuItem>
                                    <MenuItem value={2}>Reel</MenuItem>
                                    <MenuItem value={4}>Gmail</MenuItem>
                                    <MenuItem value={5}>Music</MenuItem>
                                    <MenuItem value={6}>Article</MenuItem>
                                    <MenuItem value={7}>Video</MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                style={{marginTop: "10px"}}
                                variant="contained"
                                color="primary"
                                onClick={() => this.newPersona()}
                            >
                                Submit
                            </Button>
                            <Link
                                to={
                                    !perms.p2 ? `/personas` : `/personas/edit/${newPersonaUUID}`
                                }
                            >
                                <Button
                                    disabled={!perms.p2 || !newPersona}
                                    style={{marginTop: "10px"}}
                                    variant="contained"
                                    color="primary"
                                >
                                    Go to Persona
                                </Button>
                            </Link>
                            <Button
                                style={{marginTop: "10px"}}
                                variant="contained"
                                color="primary"
                                onClick={(e) => this.close(e)}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                    <div className={openView ? "user-box" : "user-box hide"}>
                        <div className="user-container">
                            <Link to={`/facebook/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Facebook
                                </Button>
                            </Link>
                            <Link to={`/instagram/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Instagram
                                </Button>
                            </Link>
                            <Link to={`/pinterest/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Pinterest
                                </Button>
                            </Link>
                            <Link to={`/vk/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    VK
                                </Button>
                            </Link>
                            <Link to={`/twitter/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Twitter
                                </Button>
                            </Link>
                            <Link to={`/xiao/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Xiao
                                </Button>
                            </Link>
                            <Button
                                style={{marginTop: "10px"}}
                                variant="contained"
                                color="primary"
                                onClick={(e) => this.closeView(e)}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                    <div className={openReelView ? "user-box" : "user-box hide"}>
                        <div className="user-container">
                            <Link to={`/tiktok/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    TikTok
                                </Button>
                            </Link>
                            <Link to={`/snapchat/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Snapchat
                                </Button>
                            </Link>
                            <Link to={`/douyin/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Douyin
                                </Button>
                            </Link>
                            <Button
                                style={{marginTop: "10px"}}
                                variant="contained"
                                color="primary"
                                onClick={(e) => this.closeReelView(e)}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                    <div className={openVideoView ? "user-box" : "user-box hide"}>
                        <div className="user-container">
                            <Link to={`/video/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Video
                                </Button>
                            </Link>
                            <Link to={`/videoland/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Videoland
                                </Button>
                            </Link>
                            <Link to={`/itvx/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    ITVX
                                </Button>
                            </Link>
                            <Link to={`/tencent/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Tencent
                                </Button>
                            </Link>
                            <Link to={`/iq/view/${linkID}`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    IQIYI
                                </Button>
                            </Link>
                            <Link to={`/video/view/${linkID}/netflix`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Netflix
                                </Button>
                            </Link>
                            <Link to={`/video/view/${linkID}/amazon`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Amazon Prime
                                </Button>
                            </Link>
                            <Link to={`/video/view/${linkID}/disney`} target="_blank">
                                <Button size="small" variant="contained" color="secondary">
                                    Disney +
                                </Button>
                            </Link>
                            <Button
                                style={{marginTop: "10px"}}
                                variant="contained"
                                color="primary"
                                onClick={(e) => this.closeVideoView(e)}
                            >
                                Close
                            </Button>
                        </div>
                    </div>

                </>
            );
        }
    }
}

export default withSnackbar(Personas);
