import React from 'react';
import {Link} from "react-router-dom";
import Header from "../header/header";
import {authenticationService} from "../scripts/authentication.service";
import {searchService} from "../scripts/search.service";
import Items from "../api/content";
import Stims from "../api/stims";
import {cf} from "../scripts/custom-fetch";
import {
    Table, TableHead, TableRow, TableBody, TableContainer, Paper,
    Button,
    InputAdornment,
    TextField,
    Autocomplete,
    LinearProgress,
    ToggleButton, ToggleButtonGroup,
    TableCell, Tooltip,
    Pagination, Checkbox
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import Tags from "./components/tags";
import {withSnackbar} from "notistack";
import F0F from "../errors/404";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import '../styles/tables.css'

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.updateSearchTags = this.updateSearchTags.bind(this)
        this.updateSearchText = this.updateSearchText.bind(this)

        this.state = {
            type: 1,
            should404: false,
            isStim: false,
            error: null,
            currentTags: searchService.currentSearchValue.tags,
            currentSearch: searchService.currentSearchValue.search,
            deletedItems: [],
            isLoaded: false,
            openDelete: false,
            restore: false,
            deleteID: 0,
            content: [],
            maxContent: 0,
            tags: [],
            linkTags: [],
            currentSpin: searchService.currentSearchValue.spin,
            currentType: searchService.currentSearchValue.type,
            loadCount: 0,
            currentPage: searchService.currentSearchValue.page,
            itemsPerPage: 10,
            currentUser: authenticationService.currentUserValue,
            selectedRows: [],
            selectAll: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.getType()
        }
    }

    getType = () => {
        this.setState({
            currentTags: searchService.currentSearchValue.tags,
            currentSearch: searchService.currentSearchValue.search,
            currentSpin: searchService.currentSearchValue.spin,
            currentType: searchService.currentSearchValue.type,
            currentPage: searchService.currentSearchValue.page
        })
        switch (this.props.match.params.type) {
            case "content":
                this.setState({
                    type:1,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "stim":
                this.setState({
                    type:2,
                    isStim: true
                }, () => {this.getContent()})
                break;
            case "story":
                this.setState({
                    type:3,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "watch":
                this.setState({
                    type:4,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "watch_stim":
                this.setState({
                    type:5,
                    isStim: true
                }, () => {this.getContent()})
                break;
            case "reel":
                this.setState({
                    type:6,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "reel_stim":
                this.setState({
                    type:7,
                    isStim: true
                }, () => {this.getContent()})
                break;
            case "gmail":
                this.setState({
                    type:12,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "video":
                this.setState({
                    type:16,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "video_stim":
                this.setState({
                    type:17,
                    isStim: true
                }, () => {this.getContent()})
                break;
            default:
                this.setState({should404: true})
                break;
        }
    }

    /* page - optional integer */
    getContent(page){
        let option = {
            search: this.state.currentSearch,
            option: this.state.type,
            tags: this.state.currentTags,
            spin: this.state.currentSpin,
            type: this.state.currentType,
            page: page || this.state.currentPage,
        }
        if (this.state.isStim) {
            Stims.getSearchStims(option)
                .then(result => {
                    console.log("Content fetched after delete:", result.content);
                    this.setState({
                        content: result.content,
                        maxContent: result.maxContent,
                    }, () => {
                        this.getTags();
                    });
                })
        } else {
            Items.getSearchContent(option)
                .then(result => {
                    this.setState({
                        content: result.content,
                        maxContent: result.maxContent,
                    }, () => {
                        this.getTags();
                    });
                })
        }
        if (!!page) {
            this.setState({
                currentPage: page,
            });
        }
    }

    getTags() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/tag/getTags`, options)
            .then((result) => {
                    this.setState({
                        tags: result,
                    }, () => {
                        this.getLinks()
                    });
                },
                (error) =>{this.setState({error})}
            )
    }

    getLinks() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({
                'option': this.state.type
            })
        };

        cf.get(`${process.env.REACT_APP_API_GET}/tag/getLinks`, options)
            .then((result) => {
                    this.setState({
                        linkTags: result,
                        isLoaded: true,
                    });
                },
                (error) =>{this.setState({error})}
            )
    }

    openDelete = (item) => {
        this.setState({
            openDelete: true,
            deleteID: item
        })
    }

    closeDelete = () => {
        this.setState({
            openDelete: false
        })
    }

    delete = () => {
        let state
        let msg1
        let msg2
        if (this.state.restore) {
            msg1 = "Restoring"
            msg2 = "Restored"
            state = 1
        } else {
            msg1 = "Deleting"
            msg2 = "Deleted"
            state = 3
        }
        this.props.enqueueSnackbar(msg1, {variant:"info"});
        this.setState({
            openDelete: false,
        })
        Items.disable(this.state.type,state,this.state.deleteID).then(result => {
            this.getContent()
            this.setState({
                restore: false
            })
            console.log(result)
            this.props.enqueueSnackbar(msg2, {variant:"success"});
        })
    }

    openRestore = () => {
        Items.getDeletedItems(this.state.type).then(result => {
            if (result) {
                this.setState({
                    deletedItems: result,
                    restore: true
                })
            }
        })
    }

    closeRestore = () => {
        this.setState({
            restore: false
        })
    }

    componentDidMount() {
        this.getType();
    }

    setPage(event, value) {
        let data = searchService.currentSearchValue
        data.page = value
        searchService.setSearch(data)
        this.setState({
            currentPage: value
        }, () => {
            this.getContent()
        })
    }

    updateSearchTags(event, newValue) {
        let data = searchService.currentSearchValue
        data.tags = newValue
        searchService.setSearch(data)
        this.setState({
            currentTags: newValue
        })
    }

    updateSearchText(event) {
        let data = searchService.currentSearchValue
        data.search = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentSearch: event.target.value
        })
    }

    updateSearchSpin = (event) => {
        let data = searchService.currentSearchValue
        data.spin = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentSpin: event.target.value
        }, () => {
            this.getContent()
        })
    }

    updateSearchType = (event) => {
        let data = searchService.currentSearchValue
        data.type = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentType: event.target.value
        }, () => {
            this.getContent()
        })
    }

    handleSelectedRow = (id) => {
        const {selectedRows} = this.state;
        const updatedSelectedRows = selectedRows.includes(id) ? selectedRows.filter(rowId => rowId !== id) : [...selectedRows, id];

        this.setState({
            selectedRows: updatedSelectedRows,
            selectAll: updatedSelectedRows.length === this.state.content.length,
        });
    }

    handleSelectAll = (event) => {
        const {content} = this.state;
        if (event.target.checked) {
            const allRowsIds = content.map(item => item.id);
            this.setState({
                selectedRows: allRowsIds,
                selectAll: true
            });
        } else {
            this.setState({
                selectedRows: [],
                selectAll: false
            });
        }
    }

    deleteSelectedRows = async () => {
        const {selectedRows, content} = this.state;
        const state = this.state.restore ? 1 : 3;

        this.props.enqueueSnackbar("Deleting selected profiles", {variant: "info"});

        Promise.all(
            selectedRows.map(id => {
                console.log('Deleting item with id:', id);
                return Items.disable(this.state.type, state, id);
            })
        ).then(() => {
            console.log("Items deleted. Fetching updated content...");
            const updatedContent = content.filter(item => !selectedRows.includes(item.id))
            this.setState({
                content: updatedContent,
                selectedRows: [],
                selectAll: false
            });
            this.props.enqueueSnackbar("Selected profiles deleted", { variant: "success" });
        });
    };

    render() {
        const { error, isLoaded, currentPage, itemsPerPage, content, maxContent, currentSpin, currentSearch, currentType, tags, openDelete, restore, deletedItems, should404, currentUser } = this.state;
        const perms = currentUser.perms

        if (should404) {
            return <F0F />
        } else {
            let currentItems
            if (restore) {
                currentItems = deletedItems
            } else {
                currentItems = content
            }

            if (error) {
                return <div>Error: {error.message}</div>;
            } else if (!isLoaded) {
                return (
                    <>
                        <Header/>
                        <div style={{width:"50%"}}>
                            <LinearProgress />
                        </div>
                    </>
                )
            } else {
                const tagOptions = tags.map((option) => {
                    const firstLetter = option.name[0].toUpperCase();
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                        ...option,
                    };
                });
                return (
                    <>
                        <Header/>

                        <div className="content-header" style={{top: "0.5rem", width: "90%", left: "6.25rem"}}>
                            <div className="left" style={{display: "flex"}}>
                                <TextField
                                    label="Search"
                                    style={{marginRight:"10px"}}
                                    onChange={this.updateSearchText}
                                    focused
                                    size="small"
                                    value={currentSearch}
                                    className="foreInputWhite"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon color="primary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Autocomplete
                                    multiple
                                    size="small"
                                    className="foreInputWhite"
                                    renderInput={(params) => <TextField focused style={{width: "250px"}} variant="outlined" {...params} label="Tags" />}
                                    options={tagOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.updateSearchTags}
                                />
                                <ToggleButtonGroup
                                    color="primary"
                                    value={currentSpin}
                                    exclusive
                                    onChange={this.updateSearchSpin}
                                    style={{marginLeft: "10px", border: "#1565c0 2px solid"}}
                                >
                                    <ToggleButton className="testing" value="0">New</ToggleButton>
                                    <ToggleButton className="testing" value="1">Old</ToggleButton>
                                </ToggleButtonGroup>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={currentType}
                                    exclusive
                                    onChange={this.updateSearchType}
                                    style={{marginLeft: "10px", border: "#1565c0 2px solid"}}
                                >
                                    <ToggleButton className="testing" value="0">All</ToggleButton>
                                    <ToggleButton className="testing" value="1">Stims</ToggleButton>
                                    <ToggleButton className="testing" value="2">Non-Stims</ToggleButton>
                                </ToggleButtonGroup>
                                <Button variant="contained" color="primary" style={{marginLeft: "10px"}} onClick={() => {
                                    this.getContent(1);
                                }}>Search</Button>

                            </div>
                            <div className="right" style={{display: "flex"}}>
                                <Link to={`edit/${this.props.match.params.type}/new`}><Button style={{marginRight: "10px"}} variant="contained" color="primary" className="spacer">New</Button></Link>
                                {restore ? <Button variant="contained" color="primary" onClick={() => this.closeRestore()} className="spacer">Normal</Button> : <Button disabled={!perms.c3} variant="contained" color="primary" onClick={() => this.openRestore()} className="spacer">Restore</Button>}
                                <Link to="/"><Button style={{marginLeft: "10px"}} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            </div>
                        </div>

                        <div className="profiles-container" style={{width: "70%", top: "1rem", maxHeight: "80vh"}}>
                            <TableContainer component={Paper}>
                                <Table>
                                <TableHead className='table_head'>
                                    <TableRow >
                                        <TableCell className="table_cell top_row" style={{width: "20%"}}>
                                        <div style={{display: "flex"}}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                              <span style={{fontWeight: "600"}}>Select All</span>


                            <Checkbox className="table_cell top_row"checked={this.state.selectAll} onChange={this.handleSelectAll}></Checkbox>
</div>
                          {this.state.selectedRows.length > 0 && (
                            <Button variant="contained" color="secondary" size="small" onClick={this.deleteSelectedRows} style={{padding: "0.25rem", width: "60%", height: "50%", top: "2.5rem"}}>DELETE SELECTED</Button>
                          )}
                          </div>


                                        </TableCell>
                                        {/* <TableCell className="table_cell top_row" style={{width: "10%"}}></TableCell> */}
                                        <TableCell className="table_cell top_row" style={{fontWeight: "600",width: "45%"}}>Title</TableCell>
                                        <TableCell className="table_cell top_row" style={{fontWeight: "600", width: "15%"}}>Date Created</TableCell>
                                        <TableCell className="table_cell top_row" style={{fontWeight: "600"}}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentItems.map((item, index) => (
                                    <TableRow key={item.id} className={index % 2 === 0 ? 'dark_row' : 'light_row'}>
                                        {item.status === 4 && (
                                            <div className="src-warning">
                                                <Tooltip title={"Source Error"}><GppMaybeIcon color="error" /></Tooltip>
                                            </div>
                                        )}
                                        <TableCell style={{padding: "0.25rem 1.25rem" }}>
                                            <Checkbox className="table_cell" checked={this.state.selectedRows.includes(item.id)} onChange={() => this.handleSelectedRow(item.id)}></Checkbox>
                                        </TableCell>
                                        {/* <TableCell style={{ padding: "0.25rem 1rem 0.25rem 1rem", width: "80px" }}>
                                            <CardMedia style={{height: "50px", width: "50px",}}
                                                    component=
                                                    image={item.src}
                                                    title={item.title}
                                                />

                                    </TableCell>        */}
                                            <TableCell className="table_cell">
                                                <div className="hover-image" title={item.title}>
                                                {item.title}
                                                {item.src ? (
                                                    Array.isArray(item.src) ? (
                                                        item.src[0].split(".").pop() === "mp4" ? (
                                                        <div className="image-tooltip video-container">
                                                        <video muted autoPlay style={{width: "100%", height: "100%"}}>
                                                            <source src={item.src[0]} type="video/mp4" />
                                                        </video>
                                                    </div>                                                                                                      ) : (
                                                        <div className="image-tooltip"
                                                        style={{ backgroundImage: `url(${item.src})`}}>
                                                        </div>
                                                    )
                                                    ) : item.src.split(".").pop() === "mp4" ? (
                                                        <div className="image-tooltip video-container">
                                                        <video muted autoPlay style={{width: "100%", height: "100%"}}>
                                                            <source src={item.src} type="video/mp4" />
                                                        </video>
                                                        </div>
                                                ) : (
                                                    <div className="image-tooltip"
                                                        style={{ backgroundImage: `url(${item.src})`}}>
                                                        </div>
                                                )
                                                ): (
                                                    <div className="image-tooltip"></div>
                                                )}

                                                </div>
                                                </TableCell>
                                            <TableCell className="table_cell">
                                                {new Date(item.date_created).getDay()}/{new Date(item.date_created).getMonth()}/{new Date(item.date_created).getFullYear()}
                                            </TableCell>
                                            <TableCell>
                                                <div style={{justifyContent: "space-between", display: "flex"}}>
                                            <Link to={perms.c2 ? `/content/edit/${this.props.match.params.type}/${item.id}` : `/content/${this.props.match.params.type}`} >
                                                <Button disabled={!perms.c2} variant="contained" size="small" color="secondary">
                                                    Edit
                                                </Button>
                                            </Link>
                                            {/* <Button disabled={!perms.c3} variant="contained" size="small" color="secondary" onClick={() => this.openDelete(item.id)}>
                                                    {restore ? "Restore" : "Delete"}
                                                </Button>  */}
                                                </div>
                                            </TableCell>
                                   </TableRow>
                            ))}
                                </TableBody>
                            </Table>
                            </TableContainer>


                        </div>
                        <div className={(openDelete ? 'user-box' : 'user-box hide')}>
                            <div className="user-container">
                                <p>Are you sure ?</p>
                                <div style={{display: "flex", marginTop: "10px"}}>
                                    <Button variant="contained" color="primary" style={{flex: 1, marginRight: "10px"}} onClick={this.delete}>Yes</Button>
                                    <Button variant="contained" color="primary" style={{flex: 1}} onClick={this.closeDelete}>Nope</Button>
                                </div>
                            </div>
                        </div>
                        <div className="pagination-container">
                            <Pagination color="secondary" count={Math.ceil(maxContent[0].records / itemsPerPage)} page={currentPage} onChange={(event, page) => this.setPage(event, page)} />
                        </div>
                    </>
                );
            }
        }
    }
}

export default withSnackbar(Content);
